// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';

// lazysizes
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';
import AOS from 'aos';
AOS.init();

document.addEventListener('DOMContentLoaded', () => {
    const languageSelect = document.querySelector('#language-select');
    const languageNames = new Intl.DisplayNames(window.navigator.languages, { type: 'language' });
    languageSelect.querySelectorAll('option').forEach((option) => {
        option.innerHTML = languageNames.of(option.value);
    });
    languageSelect.addEventListener('change', () => {
        const selectedOption = languageSelect.options[languageSelect.selectedIndex];
        if (selectedOption.dataset.url) {
            window.location.href = selectedOption.dataset.url;
        }
    });
});